<template>
    <section id="member-section">
        <header>
            <h1>Members</h1>
        </header>
        <aside>
            <ol>
                <li>Kawaguchi Atsushi</li>
                <li>Ikeda Kouichi</li>
            </ol>
        </aside>
    </section>
</template>

<style scoped>

/* デフォルトの余白設定 */
#member-section header h1 {
    margin-bottom: 0.5rem;
}

#member-section aside {
    margin-top: 0.5rem;
}

/* タブレットサイズ（768px以下） */
@media (max-width: 768px) {
    #member-section header h1 {
        margin-bottom: 0.3rem;  /* タブレットでは余白を狭く */
    }

    #member-section aside {
        margin-top: 0.3rem; /* タブレットでは上部余白を狭く */
    }
}



/* スマホサイズ向けの調整（画面幅480px以下の場合） */
@media (max-width: 480px) {
    #member-section header h1 {
        margin-bottom: 0.1rem;  /* スマホではさらに余白を狭く */
    }

    #member-section aside {
        margin-top: 0.1rem;  /* 同様に、asideの上部余白も調整 */
    }
}
</style>