<template>
    <!-- Product -->
    <section id="product-section">
    <header>
        <h1>Product</h1>
        <p>プロダクトの説明</p>
    </header>
    <aside>
        <h1 id="product-a-section">Sanskrit Vocabulary app</h1>
        <p><img src="/static/images/sanskrit_icon.png" alt="Sanskrit Vocabulary appの画像"></p>
        <p>サンスクリット学習を少しでも楽にできるような学習アプリ</p>
        <div style="text-align: center;">
            <a href="https://apps.apple.com/jp/app/sanskrit-vocabulary-app/id6477742151?itscg=30200&itsct=apps_box_badge&mttnsubad=6477742151" style="display: inline-block;">
            <img src="https://toolbox.marketingtools.apple.com/api/v2/badges/download-on-the-app-store/black/ja-jp?releaseDate=1737331200" alt="App Storeでダウンロード" class="app-store-image" />
            </a>
        </div>
        <router-link :to="{path:'/privacypolicy'}">プライバシーポリシー</router-link>
    </aside>
    <aside>
        <h1 id="product-b-section">coming soon</h1>
        <p>略字検索アプリ開発中...</p>
    </aside>
    </section>
</template>

<style scoped>
    
.app-store-image {
    width: 224px;
    height: 82px;
    vertical-align: middle;
    object-fit: contain;
    transition: width 0.3s ease, height 0.3s ease;
}

/* スマホサイズでは小さめにする */
@media (max-width: 768px) {
    .app-store-image {
        width: 180px;
        height: 66px;
    }
}

@media (max-width: 480px) {
    .app-store-image {
        width: 150px;
        height: 55px;
    }
}
</style>