<template>
  <div id="app">
    <router-view></router-view>
    <FooterComponent />
  </div>
</template>

<script>
import FooterComponent from './components/FooterComponent.vue';


export default {
  name: 'App',
  components: {
    FooterComponent,
  },
};
</script>

<style>
/* App.vueのスタイル */
html, body, #app {
  height: 1.5;
  margin: 0;
}

#app {
  display: flex;
  flex-direction: column;
}

router-view {
  flex: 1;
}


</style>
